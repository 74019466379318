<template>
<div class="main">
  <div class="card" style="width: 18rem;">
    <img src="../assets/RMX1971.jpg" class="card-img-top" alt="Realme 5 Pro">
    <div class="card-body">
      <h5 class="card-title">Realme 5 Pro</h5>
      <p class="card-text">Realme 5 Pro builds both Realme 5 Pro & Realme Q. Enjoy all the goodies available for Realme 5 Pro in our KhraMe webpage.</p>
      <a href="/RMX1971" class="btn btn-primary">Explore</a>
    </div>
  </div>
  <div class="card" style="width: 18rem;">
    <img src="../assets/RMX1911.jpg" class="card-img-top" alt="Realme 5 Series">
    <div class="card-body">
      <h5 class="card-title">Realme 5 Series</h5>
      <p class="card-text">Realme 5 Series(r5x) is a unifed name for Realme 5,5i,5s. Enjoy all the goodies available for Realme 5 Series in our KharaMe webpage.</p>
      <a href="/r5x" class="btn btn-primary">Explore</a>
    </div>
  </div>
</div>
</template>

<script>  
  export default {
    name: 'Home',
    components: {

    },
    data: () => ({
    }),

    methods: {

    },
  }
</script>
<style scoped>
.main {
  display: flex;
  justify-content: center;
  margin-top: 2%;
}
.btn-primary {
  color: white;
}
.card {
  margin: 2%;
  min-width: 25%;
}
@media (max-width: 550px) {
  .main {
    display: block;
  }
  .card {
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 5%;
  }
}
</style>